<template>
  <BaseDialog
    :show="true"
    title="刪除狀態"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <p class="description">確定要刪除 [{{ data.newName || data.name }}] 嗎？您需要為這些訂單指定既有訂單的新狀態。</p>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem label="選擇現有狀態" prop="status">
          <BaseElSelect v-model="formData.status" value-key="id">
            <BaseElSelectOption
              v-for="option in statusOptions"
              :key="option.id"
              :value="option"
              :label="option.name"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { get, filter, find, map } from 'lodash'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'DeleteStatusModal',
  components: { BaseDialog },
  props: {
    data: { type: Object, default: () => ({}) },
    configData: { type: Object, default: () => ({}) },
    displayStatus: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    initFormData({
      status: { id: 'none', name: '無須指定（–）' },
    })

    const formRules = computed(() => {
      return {
        status: [noEmptyRules()],
      }
    })
    const statusOptions = computed(() => {
      const options = filter(props.displayStatus, (item) => {
        if (item.isRemoved) return false
        if (item.id !== props.data.id) return item
      })

      options.unshift({
        id: 'none',
        name: '無須指定（–）',
      })

      return options
    })

    const onConfirm = async () => {
      if (!await checkForm(formRef.value)) return
      emit('confirm', formData.status)
      emit('close')
    }

    return { formRef, formData, formRules, onConfirm, statusOptions }
  },
})
</script>

<style lang="postcss" scoped>
.description {
  @apply text-sub mb-[20px];
}
</style>
