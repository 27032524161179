<template>
  <BaseDialog
    :show="true"
    :title="displayTitle"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <p v-if="mode === 'edit'" class="description">請輸入欲修改的狀態名稱。</p>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem label="狀態名稱" prop="name">
          <BaseElInput v-model="formData.name" :maxlength="5" show-word-limit />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'EditStatusNameModal',
  components: { BaseDialog },
  props: {
    mode: String,
    data: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { initFormData, formRef, formData, checkForm } = useBaseForm()
    initFormData({
      name: null,
    })
    const displayTitle = computed(() => {
      if (props.mode === 'edit') return '編輯狀態'
      return '新增狀態'
    })
    const onConfirm = async () => {
      if (!await checkForm(formRef.value)) return
      emit('confirm', formData.name)
      emit('close')
    }
    const formRules = computed(() => {
      return {
        name: [noEmptyRules()],
      }
    })

    onBeforeMount(() => {
      formData.name = props.data.newName || props.data.name
    })
    return { onConfirm, formRef, formData, formRules, displayTitle }
  },
})
</script>

<style lang="postcss" scoped>
.description {
  @apply text-sub pb-[20px];
}
</style>
