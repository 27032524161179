<template>
  <div class="side-highlight-container">
    <BaseElFormItem label="流程隸屬類別">
      <BaseElInput :value="displayData.scope" disabled />
    </BaseElFormItem>
    <BaseElFormItem placeholder="請輸入名稱" prop="name">
      <template slot="label">
        <FormItemTooltipLabel label="流程名稱" :tooltipWidth="200" :iconSize="16">
          說明：請為您的自定義流程命名，用來描述這個流程，方便您判斷訂單狀態是屬於哪個流程，如：叫貨流程。
        </FormItemTooltipLabel>
      </template>
      <BaseElInput v-model="syncForm.name" :maxlength="7" show-word-limit />
    </BaseElFormItem>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { customOrderStatusModulesConfig } from '@/config/customOrderStatus'
import { get } from 'lodash'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'

export default defineComponent({
  name: 'CustomOrderStatusSettingInfoBlock',
  components: { FormItemTooltipLabel },
  props: {
    form: { type: Object, default: () => ({}) },
    formRules: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)
    const displayData = computed(() => {
      return {
        scope: get(customOrderStatusModulesConfig, `${syncForm.value.scope}.label`),
      }
    })
    return { syncForm, displayData }
  },
})
</script>
