<template>
  <div>
    <PageTitle
      :title="displayData.pageTitle"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.go(-1)"
    />
    <BaseElForm ref="formRef" v-loading="loading" :model="formData" :rules="formRules" label-position="top">
      <div class="flex flex-col gap-[24px]">
        <CustomOrderStatusSettingInfoBlock
          :form.sync="formData"
          :formRules="formRules"
        />
        <CustomOrderStatusEditBlock
          :configData="configData"
          :form.sync="formData"
          :formRules="formRules"
        />
      </div>
    </BaseElForm>

    <EmptyStatusWarnModal
      v-if="modal.emptyStatusWarn"
      @close="modal.emptyStatusWarn = false"
      @confirm="modal.emptyStatusWarn = false"
    />
    <SaveStatusWarnModal
      v-if="modal.saveWarn"
      :scope="curScope"
      @close="modal.saveWarn = false"
      @confirm="onSubmit"
      @cancel="modal.saveWarn = false"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="onCancel"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount, reactive } from 'vue'
import CustomOrderStatusSettingInfoBlock from './CustomOrderStatusSettingInfoBlock.vue'
import CustomOrderStatusEditBlock from './CustomOrderStatusEditBlock.vue'
import EmptyStatusWarnModal from './EmptyStatusWarnModal.vue'
import SaveStatusWarnModal from './SaveStatusWarnModal.vue'
import { useBaseForm, simpleSyncFormData } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { useRoute, useRouter } from 'vue-router/composables'
import { get, map, forEach, compact, filter } from 'lodash'
import { customOrderStatusModulesConfig } from '@/config/customOrderStatus'
import { CreateCustomFlow, UpdateCustomFlow, FindCustomFlow } from '@/api/customFlow'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { useFetch } from '@/use/fetch'
import { sortOrder } from '@/utils/helper'

export default defineComponent({
  name: 'EditCustomOrderStatusSetting',
  components: { CustomOrderStatusSettingInfoBlock, CustomOrderStatusEditBlock, EmptyStatusWarnModal, SaveStatusWarnModal },
  setup (props, { emit }) {
    const { initFormData, formData, formRef, checkForm, loading } = useBaseForm()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()
    const route = useRoute()
    const router = useRouter()
    const configData = ref({})
    const modal = reactive({
      emptyStatusWarn: false,
      saveWarn: false,
    })
    const curScope = computed(() => get(route, 'params.scope'))
    const targetId = computed(() => get(route, 'params.id'))

    initFormData({
      enable: false,
      scope: curScope.value,
      name: null,
      nodes: [],
      defaultNodeId: null,
    })

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        enable: [],
        nodes: [],
        defaultNodeId: [],
      }
      return rules
    })

    const displayData = computed(() => {
      const scopeLabel = get(customOrderStatusModulesConfig, `${curScope.value}.label`)
      let pageTitle = `新增${scopeLabel}流程`
      if (targetId.value) pageTitle = '編輯流程'
      return {
        pageTitle,
      }
    })
    const displayStatus = computed(() => {
      return map(formData.nodes, item => {
        return {
          id: item.id,
          name: item.newName || item.name,
          isRemoved: Boolean(item.moveTo),
        }
      })
    })

    const newNodeChangeAction = (type, data) => {
      switch (type) {
      case 'create':
        return {
          action: 'create',
          createNodeId: data.id,
          createNodeName: data.name,
        }
      case 'update':
        return {
          action: 'update',
          updateNodeId: data.id,
          updateNodeName: data.newName,
        }
      case 'delete':
        return {
          action: 'delete',
          deleteNodeId: data.id,
          deleteMoveToNodeId: data.moveTo.id === 'none' ? null : data.moveTo.id,
        }
      }
    }

    const submitData = computed(() => {
      // TOFIX  formData.nodes[0] 有可能是被標記被刪除的
      const defaultNodeId = get(filter(displayStatus.value, (item) => !item.isRemoved), '[0].id') || null
      const nodeChangeActions = []
      forEach(formData.nodes, (node) => {
        if (node.isNew) nodeChangeActions.push(newNodeChangeAction('create', node))
        if (node.newName) nodeChangeActions.push(newNodeChangeAction('update', node))
        if (node.moveTo) nodeChangeActions.push(newNodeChangeAction('delete', node))
      })
      nodeChangeActions.push({
        action: 'changeOrder',
        changeOrderList: compact(map(formData.nodes, (item, idx) => {
          if (item.moveTo) return null
          return { id: item.id, order: idx }
        })),
      })

      if (targetId.value) {
        return {
          shopId: shopId.value,
          id: targetId.value,
          enable: formData.enable,
          name: formData.name,
          defaultNodeId,
          nodeChangeActions,
        }
      }
      return {
        shopId: shopId.value,
        enable: formData.enable,
        scope: formData.scope,
        name: formData.name,
        nodes: map(formData.nodes, (item, idx) => {
          const data = {
            id: item.id,
            name: item.newName || item.name,
            order: idx,
          }
          return data
        }),
        defaultNodeId,
      }
    })

    const onCancel = async () => {
      router.push({ name: 'CustomOrderStatus' })
    }
    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }
      let apiMethod = CreateCustomFlow
      if (targetId.value) apiMethod = UpdateCustomFlow

      const [, err] = await apiMethod(submitData.value)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      if (targetId.value) window.$message.success(notifyMessage.updateSuccess)
      else window.$message.success(notifyMessage.createSuccess)

      router.push({
        name: 'CustomOrderStatus',
      })
    }

    const onConfirm = async () => {
      // 沒有任何狀態處理
      if (!formData.nodes.length || !get(filter(formData.nodes, i => !i.moveTo), 'length')) {
        loading.value = false
        modal.emptyStatusWarn = true
        return
      }

      // TOFIX 如果值有變動才跳警告
      if (targetId.value) modal.saveWarn = true
      else await onSubmit()
    }

    const syncData = (data) => {
      simpleSyncFormData(data, formData)
      formData.nodes = sortOrder(formData.nodes)
    }

    onBeforeMount(async () => {
      if (!targetId.value) return
      loading.value = true
      simpleFetch(FindCustomFlow, { shopId: shopId.value, id: targetId.value }, (res) => {
        syncData(res)
        configData.value = res
        loading.value = false
      })
    })

    return {
      configData,
      formData,
      formRef,
      formRules,
      curScope,
      onCancel,
      onConfirm,
      displayData,
      submitData,
      loading,
      modal,
      onSubmit,
    }
  },
})
</script>
